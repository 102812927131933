<template>
  <v-data-table
    :headers="headers"
    :items="allUsers"
    :items-per-page="6"
    class="elevation-2"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>All Users</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.avatar="{ item }">
      <div class="pa-2">
        <v-avatar size="48px">
          <v-img
            :src="imgURL + '/' + item.id + '/avatar/' + item.avatar"
            :alt="item.avatar"
            height="48px"
          ></v-img>
        </v-avatar>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      imgURL: "https://100milesrealtors.com/routes/storage/images",
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "Profile Photo",
          value: "avatar",
          sortable: false,
          align: "center",
        },
        { text: "Email Id", value: "email", sortable: false },
        { text: "User Name", value: "name", sortable: false },
        {
          text: "User Type",
          value: "role.user_type",
          sortable: false,
          align: "center",
        },

        { text: "Contact", value: "contact", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState({
      allUsers: (state) => state.authenticate.allUsers,
    }),
  },
  mounted() {
    this.getAllUser();
  },
  methods: {
    ...mapActions(["getAllUser"]),
  },
};
</script>

<style></style>
